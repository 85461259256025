.Footer {
    font-family: "Averta Regular", Arial, sans-serif;
    /*border-top: 20px solid #2D2D2D;*/
    background-color: var(--white);
    color: #adafaf;
    display: flex;
    justify-content: center;
    padding: 24px 0;
}

.Footer-OfferLink,
.Footer-OfferLink:hover,
.Footer-OfferLink:active,
.Footer-SubscriptionLink,
.Footer-SubscriptionLink:hover,
.Footer-SubscriptionLink:active {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.2;
    color: var(--black);
    display: block;
    margin: 0 0 24px 0;
}

.Footer p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
}

.Footer-CategoriesLinkWrap {
    padding: 0 0 24px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
}

.Footer-CategoryLink:hover,
.Footer-CategoryLink:active {
    color: #FFA900;
}

.Footer-Link {
    color: var(--grey);
    line-height: 40px;
    height: 40px;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    padding: 0 14px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.Footer .TextLink,
.Footer .TextLink:hover,
.Footer .TextLink:active,
.Footer-Text a,
.Footer-Text a:hover,
.Footer-Text a:active {
    display: inline;
    color: #7A7A7A;
    text-decoration: revert;
}

.Footer-Text {
    font-size: 12px;
    color: #969696;
    text-align: justify;
    hyphens: auto;
}

.Footer-LogoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Footer-LogoContainer span {
    font-size: 12px;
}

.Footer-LogoContainer span {
    color: #7A7A7A;
}

.Footer-LogoLink,
.Footer-LogoLink:hover,
.Footer-LogoLink:active {
    height: 24px;
    margin-right: 10px;
}

.Footer-LogoContainer img {
    filter: invert(0.3);
}


.Footer-Logo {
    height: 24px;
}

.Footer .HomeLink {
    text-decoration: none;
}

.Footer-Wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
}

.Footer-OfferLinks {
    display: flex;
    flex-direction: column;
}

.Footer-RightBlock,
.Footer-LeftBlock {
    flex: 1;
}


@media (min-width: 470px) {
    .Footer-CategoriesLinkWrap {
        flex-wrap: wrap;
        flex-direction: row;
    }

    .Footer-CategoryLink {
        margin-right: 10px;
    }

}


@media (min-width: 720px) {
    .Footer-Text {
        margin-top: 10px;
    }

    .Footer-LogoContainer {
        justify-content: space-between;
        margin: 0;
    }
}

@media (min-width: 1024px) {

    .Footer-Wrapper {
        max-width: 1272px;
        position: relative;
    }

    .Footer-OfferLinks {
        flex-direction: row;
    }

    .Footer-SubscriptionLink,
    .Footer-SubscriptionLink:hover,
    .Footer-SubscriptionLink:active {
        margin-right: 50px;
    }

    .Footer-OfferLink:hover,
    .Footer-OfferLink:active,
    .Footer-SubscriptionLink:hover,
    .Footer-SubscriptionLink:active {
        color: #FFA900;
    }

    .Footer-CategoryLink {
        line-height: 18px;
        margin-left: 24px;
        margin-right: 0;
        padding-right: 0;
    }

    .Footer-LogoContainer {
        justify-content: start;
        margin-top: 24px;
    }

    .Footer-LogoLink,
    .Footer-LogoLink:hover,
    .Footer-LogoLink:active {
        margin-right: 50px;
    }
}

@media (min-width: 1200px) {
    .Footer-CategoriesLinkWrap {
        position: absolute;
        top: 0;
        right: 0;
        width: 250px;
        flex-wrap: wrap;
        justify-content: end;
        margin-top: 0;
        padding-bottom: 0;
    }

    .Footer_isPlayerOpen {
        padding-bottom: 60px;
    }

    .Footer-OfferLinks {
        display: flex;
        justify-content: flex-start;
    }

    .Footer-Text {
        margin-top: auto;
    }


}

