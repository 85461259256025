.ContentAdditional {
    color: #000;
    flex-direction: column;
    margin: auto;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(1, auto);
    margin-bottom: 24px;
}

.ContentAdditional_theme_dark {
    color: #fff;
}


/*@media (max-width: 800px) {*/

/*    .ContentAdditional {*/
/*        !*grid-auto-flow: dense;*!*/
/*    }*/

/*}*/

@media (min-width: 720px) {
    .ContentAdditional {
        grid-template-columns: repeat(2, auto);
    }
}

@media (min-width: 1024px) {
    .ContentAdditional {
        padding-bottom: 40px;
        margin: 0;
        grid-template-columns: repeat(3, auto);
    }

    .ContentAdditional .ContentItemPreview:nth-child(4n) {
        display: none;
    }

    .ContentPage_page_text .ContentAdditional {
        grid-template-columns: repeat(1, auto);
    }
}


@media (min-width: 1272px) {
    .ContentAdditional {
        grid-template-columns: repeat(4, auto);
    }

    .ContentAdditional .ContentItemPreview:nth-child(4n) {
        display: block;
    }
}
