.CategoryPage {
}

.CategoryPage .CategoryItem {
    background-color: transparent;
}

.CategoryPage-Content {
    padding-top: 84px;
}

.CategoryPage-Content:before {
    position: absolute;
    display: block;
    content: '';
    aspect-ratio: 1 / 1;
    width: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: cover;
}

.CategoryPage-Content_type_press.CategoryPage-Content:before {
    background-image: url("../Images/press.jpg");
}

.CategoryPage-Content_type_sport.CategoryPage-Content:before {
    background-image: linear-gradient(180deg, transparent 80% , white 99% ), url("../Images/sport.jpg");
}

.CategoryPage-Content_type_style.CategoryPage-Content:before {
    background-image: linear-gradient(180deg, transparent 80% , white 99% ), url("../Images/style.jpg");
}

.CategoryPage-Content_type_beauty.CategoryPage-Content:before {
    background-image: linear-gradient(180deg, transparent 80% , white 99% ), url("../Images/beauty.jpg");
}

.CategoryPage-Content_type_pitanie.CategoryPage-Content:before {
    background-image: linear-gradient(180deg, transparent 80% , white 99% ), url("../Images/food.jpg");
}

.CategoryPage-Content_type_semya.CategoryPage-Content:before {
    background-image: linear-gradient(180deg, transparent 80% , white 99% ), url("../Images/family.jpg");
}

.CategoryPage-Content_type_press .CategoryItem-LinkWrap {
    margin-top: 200px;
}

@media (min-width: 720px) {
    .CategoryPage-Content:before {
        height: 360px;
        aspect-ratio: unset;
        background-position: 0, 50% -130px;
    }

    .CategoryPage-Content_type_press .CategoryItem-LinkWrap {
        margin-top: 154px;
    }
}

@media (min-width: 1024px) {
    .CategoryPage-Content:before {
        background-position: 50%;
    }


    .CategoryPage-Content_type_press .CategoryItem-Wrapper {
        max-width: unset;
    }

    .CategoryPage-Content_type_sport.CategoryPage-Content:before {
        background-image: url("../Images/sport.jpg");
    }

    .CategoryPage-Content_type_style.CategoryPage-Content:before {
        background-image: url("../Images/style.jpg");
    }

    .CategoryPage-Content_type_beauty.CategoryPage-Content:before {
        background-image: url("../Images/beauty.jpg");
    }

    .CategoryPage-Content_type_pitanie.CategoryPage-Content:before {
        background-image: url("../Images/food.jpg");
    }

    .CategoryPage-Content_type_semya.CategoryPage-Content:before {
        background-image: url("../Images/family.jpg");
    }
}
