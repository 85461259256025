.PressContentBlock {
    display: grid;
    grid-row-gap: 12px;
    grid-column-gap: 12px;
    padding: 0 12px;
    grid-template-columns: repeat(2, calc(50% - 6px));
}

.PressContentBlock-MoreButton {
    height: 48px;
    width: 180px;
    color: #969696;
    font-size: 16px;
    font-weight: 700;
    line-height: 48px;
    border-radius: 12px;
    border: 2px solid #969696;
    margin: 12px auto 24px;
    padding-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PressContentBlock-MoreButton img {
    transform: rotate(-90deg);
    filter: brightness(0.6);
    margin-left: 12px;
    margin-top: 5px;
}

.PressContentBlock-MoreButton_isMore img {
    transform: rotate(90deg);
}

@media (min-width: 720px) {
    .PressContentBlock {
        grid-row-gap: 24px;
        grid-column-gap: 24px;
        padding: 0 24px;
        grid-template-columns: repeat(3, calc(100%/3 - 16px));
    }

    .PressContentBlock-MoreButton {
        margin-bottom: 36px;
    }
}

@media (min-width: 1024px) {
    .PressContentBlock {
        padding: 0;
        width: 100%;
        grid-column-gap: 12px;
        grid-template-columns: repeat(6, calc(100%/6 - 10px));
    }

    .PressContentList-Wrapper {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .PressContentBlock-MoreButton {
        margin-left: 0;
        margin-right: 0;
        align-self: end;
    }



}
