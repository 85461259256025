.Menu {
    min-width: 320px;
    font-family: "Averta Regular", Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 24px;
    width: 100%;
    padding-top: 84px;
    color: var(--space);
    min-height: 84px;
}

.Menu-Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Menu_visible {
    display: block;
}

.Menu-List {
    width: 100%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 24px 0;
    background-color: var(--white);
}

.Menu-Link,
.Menu-Link:hover,
.Menu-Link:active {
    display: flex;
    justify-content: center;
    text-decoration: none;
    text-align: left;
    height: 48px;
    line-height: 48px;
    color: currentColor;
    position: relative;
}

/*.Menu-Item:before {*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    /*left: 0;*/
    /*content: '';*/
    /*display: block;*/
    /*width: 25px;*/
    /*height: 25px;*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: contain;*/
/*}*/

.Menu-Item:hover,
.Menu-Item:active {
}

@media (min-width: 720px) {
    .Menu {
        font-size: 24px;
    }

    .Menu-Link,
    .Menu-Link:hover,
    .Menu-Link:active {
        line-height: 90px;
        height: 90px;
    }
}


@media (min-width: 1200px) {
    .Menu {
        width: 100%;
        padding-top: 0;
        font-weight: 400;
        font-size: 14px;
    }

    .Menu-Wrapper {
        width: 600px;
        flex-direction: row;
        background-color: transparent;
    }

    .Menu-List {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: transparent;
    }

    .Menu-Item {
        flex-grow: 1;
        padding: 0 0 0 15px;
    }

    .Menu-Item:before {
        display: none;
    }

    .Menu-Link,
    .Menu-Link:hover,
    .Menu-Link:active {
        justify-content: flex-end;
        height: 84px;
        line-height: 84px;
    }

    .Menu_ofPage_CategoryPage .Menu-Link,
    .Menu_ofPage_CategoryPage .Menu-Link:hover,
    .Menu_ofPage_CategoryPage .Menu-Link:active {
        color: var(--white);
    }


    .Menu-ItemLink-Text {
        font-style: normal;
        font-size: 14px;
        text-transform: none;
        /*line-height: 50px;*/
        text-align: center;
    }

    .Menu-Link:hover .Menu-ItemLink-Text,
    .Menu-Link:active .Menu-ItemLink-Text {
        color: #FFA900;
    }
}
